/* Dark mode background and text colors */
.chat-log {
    background-color: #1e1e1e;
    color: #f1f1f1;
    padding: 15px;
    border-radius: 10px;
    height: 500px;
    width: 90%;
    overflow-y: auto;
    margin: 20px auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid #333;
    font-family: 'Arial', sans-serif;
}

.chat-message {
    margin: 10px 0;
    padding: 8px;
    border-radius: 8px;
    background-color: #2c2c2c;
    word-wrap: break-word;
    max-width: 80%;
}

/* Style for user messages (you) */
.chat-message-sender {
    color: #76c7c0;
    background-color: #444;
    text-align: left;
    padding: 10px;
    display: block;
    border-radius: 10px;
}

/* Style for model/AI messages */
.chat-message-receiver {
    color: #f1f1f1;
    background-color: #444;
    text-align: left;
    padding: 10px;
    border-radius: 10px;
}

/* Chat form styling */
.chat-form {
    width: 90%;
    display: flex;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto; /* Centers the form horizontally */
}

/* Chat input field styling */
.chat-input {
    flex-grow: 1;
    padding: 10px;
    background-color: #333;
    border: 1px solid #555;
    border-radius: 5px;
    color: #f1f1f1;
    margin-right: 10px;
    font-size: 1em;
}

.chat-input::placeholder {
    color: #888;
}

.send-button {
    background-color: #76c7c0;
    border: none;
    padding: 10px 20px;
    color: #fff;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.send-button:hover {
    background-color: #5aa49c;
}

/* Scroll bar styling */
.chat-log::-webkit-scrollbar {
    width: 10px;
}

.chat-log::-webkit-scrollbar-track {
    background: #333;
}

.chat-log::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
    border: 2px solid #333;
}
