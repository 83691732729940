/* --------- */
/* About */
/* --------- */

.about-content {
    padding: 20vh 4vw !important;
    color: white;
    text-align: center;
}

.about-activity {
    list-style: none !important;
    text-align: left !important;
    padding-left: 1px !important;
}

.blockquote-footer {
    color: var(--primary-color) !important;
}

.about-image {
    padding-top: 4em;
}

.about-skill-divider {
    padding-top: 10vh;
}

.github-stats {
    margin: 4vh 0;
    width: min(90vw, 600px);
    transition: all 0.4s ease 0s !important;
}

.github-stats:hover {
    transition: all 0.4s ease 0s !important;
    transform: scale(1.1) !important;
}

.typewriter-wrapper-about {
    font-family: "Courier Prime", serif;
    font-size: 2em !important;
    color: #ed9b37 !important;
    font-weight: 500 !important;
}

.typewriter-wrapper-about .Typewriter__cursor {
    font-size: 1em !important;
    color: #ed9b37 !important;
}

@media (max-width: 1200px) {
    .about-image {
        padding-top: 2em !important;
    }
}

@media (max-width: 576px) {
    .typewriter-wrapper-about {
        font-size: 1.5em !important;
    }
}



.tech-icons {
    font-weight: 1000 !important;
    padding: 10px !important;
    margin: 10% 0 !important;
    height: 80% !important;
    width: 100% !important;
    border: 2px solid #420a81 !important;
    /*border: 2px solid var(--secondary-color) !important;*/
    border-radius: 5px !important;
    box-shadow: 0 0 20px rgba(89, 4, 168, 0.3) !important;
    transition: all 0.4s ease 0s !important;
}

.tech-icons:hover {
    font-size: medium !important;
    transition: all 0.4s ease 0s !important;
    transform: scale(1.1) !important;
    overflow: hidden !important;
    border: 2px solid var(--primary-color) !important;
    /*border: 2px solid #54b7e1 !important;*/
}