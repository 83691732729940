.footer {
    background-color: rgb(9, 0, 14);
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important;
}

.footer-paragraph {
    text-align: center !important;
}

.footer h3 {
    font-size: 1em;
    color: white !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.footer-icon-list {
    justify-content: center !important;
    text-align: center !important;
    display: flex;
}

.footer-icons {
    padding-right: 30px;
}

.footer-social-icons {
    position: relative !important;
    font-size: 1.2em !important;
    color: #ffffff;
    transition: 0.5s !important;
}

.footer-social-icons:hover {
    color: var(--secondary-color);
    transition: 0.5s !important;
}