.sticky {
    background-color: rgb(9, 0, 14) !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0 10px 10px 0 rgba(9, 5, 29, 0.171) !important;
    backdrop-filter: blur(15px) !important;
}

.navbar {
    position: fixed !important;
    transition: all 0.3s ease-out 0s !important;
    padding: 0.5rem 8vw !important;
    font-size: 1.2rem !important;
}

@media (max-width: 992px) {
    .navbar {
        background-color: rgb(9, 0, 14) !important;
        padding: 1rem 8vw !important;
        font-size: 1.4rem !important;
    }

    .navbar-nav .nav-item a::after {
        display: none !important;
    }
}

.navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.navbar-toggler span {
    display: block !important;
    background-color: whitesmoke !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
}



.navbar-brand {
    color: rgb(250, 250, 250) !important;
}

.logo {
    height: 2em !important;
    width: 2em !important;
}

.navbar-nav .nav-link {
    color: white !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.nav-link {
    padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
    .nav-link {
        padding: 0.7rem 1rem !important;
    }
}

.navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
}

.navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
}

.navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: var(--primary-color);
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
    width: 100%;
}